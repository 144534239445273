import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    email: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
});

export const forgotPassword = Yup.object().shape({
    email: Yup.string().required("This field is required"),
});

export const signupSchema = Yup.object().shape({
    // surName: Yup.string().required("This field is required"),
    email: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
});

export const signupInfoSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    age: Yup.string().required("This field is required"),
    gender: Yup.string().required("This field is required"),
});

export const updatePasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required('This field is required'),
    reEnterNewPassword: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('newPassword'), ""], 'Passwords must match'),
  });