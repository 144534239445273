import React from "react";
import './Header.scss';
import { Text } from "../Text";
import { useNavigate } from "react-router-dom";

export const Header = ({ headerRightArea }) => {
  const Navigate = useNavigate();
  return (
    <header className="Header">
      <div className="LogoContainer" onClick={() => Navigate('/')}>
        <img className="HeaderLogo" src={"picture/logo.png"} alt={'logo'} />
        <Text className={"Text1"} variant={"Heading4"} >SUNNDIO</Text>
        <Text className={"Text2"} variant={"Heading4"}>health</Text>
      </div>
      {headerRightArea && <div className="HeaderRightArea">
        {headerRightArea}
      </div>}
    </header>
  );
};
