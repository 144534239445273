import InputField from "../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginSchema } from "../../utils/validationSchema";
import logo from "../../assets/images/logo.svg";
import bannerSrc from "../../assets/images/LoginPicture.png";
import { useTranslation } from "react-i18next";
import Button from "../../components/AuthButton/Button";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";
import { Footer } from "../../components";
import { useEffect, useState } from "react";

const Login = () => {

    const { t } = useTranslation()
    const navigation = useNavigate();
    const { pathname } = useLocation();
    const [signUpPath, setSignUpPath] = useState("");
    const [forgotPasswordpath, setForgotPasswordPath] = useState("")

    const initialValues = {
        email: "",
        password: "",
    }

    const currentTime = new Date();
    const timeDifferenceInMinutes = currentTime.getTimezoneOffset();

    useEffect(() => {
        if (pathname === "/login") {
            setSignUpPath("/signup")
            setForgotPasswordPath("/forgotPassword")
        } else {
            setSignUpPath("/home/signup")
            setForgotPasswordPath("/home/forgotPassword")
        }

    }, [pathname])


    const handleLogin = async (values) => {
        const data = {
            email: values.email.toLowerCase(),
            password: values.password,
            timeDifference: timeDifferenceInMinutes,
            isAdmin: false,
        };

        const response = await api.post(apiEndpoint.signIn, data);

        if (response.data.responseStatus === 1) {
            localStorage.setItem("token", response.data.userDetails?.accessToken)
            const nameArr = response.data.userDetails.name.split(" ")
            console.log({nameArr})
            const values = {
                name : nameArr[0],
                age : response.data.userDetails.age,
                gender : response.data.userDetails.gender
            }
            localStorage.setItem("formData", JSON.stringify(values));
            if (pathname === "/home/login") {
                navigation("/")
            } else {
                navigation("/treatmentLoading");
            }
        }
    };

    return (
        <>
            <div className="flex h-screen items-stretch text-black">
                <div className="w-screen h-full overflow-y-scroll sm:w-1/2 xl:p-16 p-8">
                    <Link to="/" className="flex items-center gap-2 xl:justify-start justify-center">
                        <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
                        <p className="xl:text-[20px] text-[14px] font-[700] p-0" >
                            SUNNDIO Health
                        </p>
                    </Link>
                    <div>
                        <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">{t("welcome")}</p>
                    </div>
                    <div>
                        <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-6 mt-4">{t("loginWith")}</p>
                    </div>
                    <Formik
                        onSubmit={handleLogin}
                        initialValues={initialValues}
                        validationSchema={loginSchema}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col items-center sm:block h-42 sm:pl-16.5 w-full">
                                <div className="w-full xl:mt-8 mt-6">
                                    <InputField id="email" label={t("emailAddress")}>
                                        {errors.email && touched.email ? (
                                            <div className="text-red-500">{errors.email}</div>
                                        ) : null}
                                    </InputField>

                                    <InputField id="password" label={t("password")}>
                                        {errors.password && touched.password ? (
                                            <div className="text-red-500">{errors.password}</div>
                                        ) : null}
                                        <p className="w-full mt-5 text-end block font-bold text-[#3b00d1]">
                                            <Link to={forgotPasswordpath}>{t("forgotPassword")}</Link>
                                        </p>
                                    </InputField>
                                    <Button text={t("login")} />
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <p className="text-[16px] text-center font-[400]">{t("notAccount")} <Link to={signUpPath} className="text-[#3b00d1]">{t("signUpWithEmail")}</Link></p>
                </div>
                <div className="justify-center items-center w-1/2 hidden sm:flex">
                    <img
                        className="w-[100%] h-full"
                        src={bannerSrc}
                        alt="Login"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Login;
