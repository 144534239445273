import InputField from "../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { signupSchema } from "../../utils/validationSchema";
import signupSrc from "../../assets/images/signup1.png";
import logo from "../../assets/images/logo.svg";
import Button from "../../components/AuthButton/Button";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Signup = () => {

    const { t } = useTranslation()
    const navigation = useNavigate()
    const [loginPath, setLoginPath] = useState("");
    const { pathname } = useLocation()

    const initialValues = {
        email: "",
        password: "",
    };


    useEffect(() => {
        if (pathname === "/signup") {
            setLoginPath("/login")
        } else {
            setLoginPath("/home/login")
        }

    }, [pathname])

    const handleSignup = async (values) => {
        const data = {
            email: values.email.toLowerCase(),
            password: values.password,
        };

        const response = await api.post(apiEndpoint.checkEmail, { email: values.email.toLowerCase() })

        if (response.data.responseStatus === 1) {
            if (pathname === "/signup") {
                navigation("/signup/info", { state: data })
            } else {
                navigation("/home/signup/info", { state: data })
            }

        } else {
            toast.error(response.data.error)
        }


    };

    return (
        <>
            <div className="flex h-screen items-stretch text-black">
                <div className="w-screen h-full overflow-y-scroll sm:w-1/2  xl:p-16 p-8">
                    <Link to="/" className="flex items-center gap-2 xl:justify-start justify-center">
                        <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
                        <p className="xl:text-[20px] text-[14px] font-[700] p-0" >
                            SUNNDIO Health
                        </p>
                    </Link>
                    <div>
                        <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">{t("welcome")}</p>
                    </div>
                    <div>
                        <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-3 mt-2">{t("loginWith")}</p>
                    </div>
                    <Formik
                        onSubmit={handleSignup}
                        initialValues={initialValues}
                        validationSchema={signupSchema}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col xl:mt-2 mt-5 items-center sm:block h-42 sm:pl-16.5 w-full">
                                <div className="w-full">
                                    {/* <InputField
                                        id="surName"
                                        label={t("surName")}
                                    >
                                        {errors.surName && touched.surName ? (
                                            <div className="text-red-500">{errors.surName}</div>
                                        ) : null}
                                    </InputField> */}
                                    <InputField
                                        id="email"
                                        label={t("emailAddress")}
                                    >
                                        {errors.email && touched.email ? (
                                            <div className="text-red-500">{errors.email}</div>
                                        ) : null}
                                    </InputField>
                                    <InputField
                                        id="password"
                                        label={t("password")}
                                    >
                                        {errors.password && touched.password ? (
                                            <div className="text-red-500">{errors.password}</div>
                                        ) : null}
                                    </InputField>
                                    <Button text={t("next")} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <p className="text-[16px] text-center font-[400]">{t("alreadyAccount")} <Link to={loginPath} className="text-[#3b00d1]">{t("login")}</Link></p>
                </div>
                <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
                    <img
                        className="w-full h-full"
                        src={signupSrc}
                        alt="hand holding globe"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Signup;
