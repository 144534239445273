import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./HomeScreen.scss";
import { Text, Button, FooterTopBar, Footer } from "../../components";

const HomeScreen = () => {
  const Navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
    localStorage.setItem("language", e);
  };

  const token = localStorage.getItem("token");
  return (
    <>
      <div className="HomeContainer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: 10,
            width: "95vw",
            position: "absolute",
            top: 0,
            gap: "10px"
          }}
        >
          <div className="flex">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: 6,
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("en")}
            >
              <span style={{ fontSize: "regular" }}>ENG</span>
              <div className="flag-icon">&#x1F1FA;&#x1F1F8;</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("es")}
            >
              <span style={{ fontSize: "regular" }}>ESP</span>
              <div className="flag-icon">&#x1F1EA;&#x1F1F8;</div>
            </div>
          </div>

        </div>

        <div />
      </div>
      <div className="Home-Background">
        <div className="Middle-Blur" />
      </div>
      <div className="Home-Screen-Top-Overlay" alt="">
        <div className="HomeTitle">
          <Text variant="Heading1">SUNNDIO</Text>
          <Text className={"HomeTitleDescription"} variant="Heading4">
            {t("homeTitleDescription")}
          </Text>
        </div>
      </div>
      <div className="Home-Screen-Remaining-Overlay" alt="">
        <div className="HomeControls">
          <Text className={"HomeQuestion"} variant="Heading3">
            <p>{t("homeQuestion")}</p> <p>{t("homeQuestion1")}</p>
          </Text>
          <Button
            className={"HomeButton"}
            onClick={() => Navigate("/patientForm")}
          >
            {t("homeButtonText")}
          </Button>
          {
            !token && <>
              <p className="p-0 m-0">{t("or")}</p>
              <button className="Button mt-2">
                <Link to="/home/login" className="ButtonText">{t("login").toUpperCase()}</Link>
              </button>
            </>
          }

        </div>
      </div>
      <img className="Home-Circle-Top-Overlay" alt="" />
      <div className="Home-Screen-Bottom-Overlay">
        <FooterTopBar />
        <div className="Home10">
          <img className="Home10background" alt="" />
          <Text className={"Home10Text"}>10</Text>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeScreen;
